<template>
  <div class="chart"></div>
</template>

  <script>
import * as echarts from "echarts";
import _ from "lodash";

export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      chart: null,
      commonOptions: null,
    };
  },
  watch: {
    options: {
      handler: function () {
        this.setOption();
      },
      deep: true,
    },
    '$route'(to, from) {
      this. this.init(); // 路由变化时更新图表
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let chartDom = this.$el;
      this.chart = echarts.init(chartDom, "light");

      window.addEventListener("resize", this.resize);

      this.setOption();
    },
    setOption() {
      let baseOptions = {
        backgroundColor: "rgba(0,0,0,0)",
        grid: {
          top: "20px",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          nameTextStyle: {
            fontSize: 16,
          },
          axisLabel: {
            fontSize: 16,
          },
        },
        yAxis: {
          nameTextStyle: {
            fontSize: 16,
          },
          axisLabel: {
            fontSize: 16,
          },
        },
      };

      // let options = { ...commonOptions, ...this.options };

      let options = _.merge(baseOptions, this.commonOptions, this.options);

      options = this.handlerOptions(options) || options;

      if (options && this.chart) {
        this.chart.setOption(options);
      }
    },
    getCharts() {
      return this.chart;
    },
    resize() {
      this.$nextTick(() => {
        this.chart?.resize();
      });
    },
    dispose() {
      if (this.chart) {
        this.chart.dispose();
      }

      window.removeEventListener("resize", this.resize);
    },
    // 用于拿到完整的 options 后，做处理后再 setOption 输入输出为 options 对象
    handlerOptions() {},
  },
  beforeDestroy() {
    if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
  },
};
</script>

  <style lang="less" scoped>
.chart {
  width: 100%;
  height: 100%;
  // padding: 30px;
  position: relative;
}
</style>
